@charset "UTF-8";
*, ::after, ::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box
}
* {
	margin: 0;
	padding: 0
}
details, main {
	display: block
}
h1, h2, h3, h4, h5 {
	font-size: inherit;
	font-weight: inherit
}
ol, ul {
	list-style: none
}
a:active, a:hover {
	outline-width: 0
}
small {
	font-size: 80%
}
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}
sub {
	bottom: -.25em
}
sup {
	top: -.5em
}
[hidden] {
	display: none
}
button, input, select, textarea {
	font: inherit
}
select::-ms-expand {
	display: none
}
select::-ms-value {
	color: currentColor
}
[aria-busy=true] {
	cursor: progress
}
[aria-controls] {
	cursor: pointer
}
[aria-disabled] {
	cursor: default
}
html {
	font-size: 62.5%
}
body {
	line-height: 1.8;
	font-size: 1.6rem;
	font-family: 'Sawarabi Mincho', '游明朝', YuMincho, serif;
	font-weight: 400;
	color: #000;
	background-color: #f3f1e6;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale
}
iframe, img, svg, video {
	max-width: 100%;
	vertical-align: top
}
p {
	margin-bottom: 1.8em
}
a {
	color: #d94745;
	text-decoration: none
}
:last-child {
	margin-bottom: 0!important
}
.w100 {
	width: 100%
}
.w672 {
	max-width: 672px
}
.iframe-embed {
	width: 100%;
	height: 100%;
	border: 0
}
.of-cover {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	font-family: 'object-fit: cover;'
}
.hv-line, .hv-op {
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
	transition-timing-function: cubic-bezier(.25, .8, .25, 1)
}
.hv-line {
	-webkit-transition-property: background-size;
	transition-property: background-size;
	background: -webkit-gradient(linear, left top, left bottom, from(currentColor), color-stop(50%, currentColor)) no-repeat bottom right;
	background: linear-gradient(currentColor 0, currentColor 50%) no-repeat bottom right;
	background-size: 100% 2px
}
.no-touch .hv-line:hover {
	background-size: 0 2px
}
.hv-op {
	-webkit-transition-property: opacity;
	transition-property: opacity
}
.no-touch .hv-op:hover {
	opacity: .7
}
body:not(.p-home) {
	padding-top: 64px
}
.l-wrap {
	width: auto;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	padding-bottom: 100px;
}
.l-wrap.-blue {
	padding-top: 32px;
	padding-bottom: 32px;
	color: #fff;
	background: url(../img/cmn/section_blue_back.png)
}
.l-section {
	margin-bottom: 64px
}
.l-section.-mb0 {
	margin-bottom: 0
}
.l-section.-blue {
	color: #fff;
	background: url(../img/cmn/section_blue_back.png)
}
#header, .l-section.-white {
	background-color: #fff
}
.l-main.-ptb {
	padding: 48px 0
}
.l-w670 {
	margin-left: auto;
	margin-right: auto;
	max-width: 670px
}
#header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 2;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	height: 64px;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
	transition-timing-function: cubic-bezier(.25, .8, .25, 1)
}
.p-home #header {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%)
}
.h-fixed #header {
	-webkit-transform: translateY(0);
	transform: translateY(0)
}
.headerLogo {
	width: 96px
}
#toggle, .toggleBtn {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column nowrap
}
#toggle {
	z-index: 4;
	right: 0;
	top: 0;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	width: 64px;
	height: 64px;
	padding-top: 8px;
	line-height: 1;
	cursor: pointer
}
.p-home #toggle {
	position: absolute;
	color: #fff
}
#toggle, .h-fixed #toggle {
	position: fixed;
	color: #000
}
.h-opened #toggle {
	color: #fff
}
.toggleBtn {
	-webkit-box-pack: justify;
	justify-content: space-between;
	width: 32px;
	height: 18px;
	margin-bottom: 8px
}
#nav, .toggleBtn>.bar {
	width: 100%;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
	transition-timing-function: cubic-bezier(.25, .8, .25, 1)
}
.toggleBtn>.bar {
	display: block;
	height: 2px;
	background-color: currentColor;
	-webkit-transition-property: all;
	transition-property: all
}
.toggleBtn>.bar.bar02 {
	width: 72%
}
.h-opened .toggleBtn>.bar.bar01 {
	-webkit-transform: translateY(8px) rotate(45deg);
	transform: translateY(8px) rotate(45deg)
}
.h-opened .toggleBtn>.bar.bar02 {
	background-color: transparent
}
.h-opened .toggleBtn>.bar.bar03 {
	-webkit-transform: translateY(-8px) rotate(-45deg);
	transform: translateY(-8px) rotate(-45deg)
}
.toggleText {
	font-size: 1rem;
	font-weight: 700
}
#nav {
	position: fixed;
	z-index: 3;
	left: 100%;
	top: 0;
	height: 100%;
	padding: 40px 20px;
	color: #fff;
	background-color: rgba(229, 63, 71, .95);
	overflow-y: auto;
	-webkit-transition-property: left;
	transition-property: left
}
.h-opened #nav {
	left: 0
}
.navLogo {
	display: block;
	width: 161px;
	margin: 0 auto 24px
}
.navList, .navList>.item {
	display: -webkit-box;
	display: flex
}
.navList {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column nowrap;
	max-width: 270px;
	margin: 0 auto
}
.navList>.item {
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	height: calc((100vh - 188px)/7);
	min-height: 48px;
	line-height: 1.2;
	font-size: 1.8rem;
	color: inherit;
	-webkit-transition-property: background-color;
	transition-property: background-color;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-timing-function: cubic-bezier(.25, .8, .25, 1);
	transition-timing-function: cubic-bezier(.25, .8, .25, 1)
}
.navList>.item:nth-child(n+2) {
	border-top: 1px solid rgba(255, 255, 255, .32)
}
.no-touch .navList>.item:hover {
	background-color: rgba(255, 255, 255, .16)
}
#footer {
	padding: 32px 0;
	color: #fff;
	background-color: #ba2a2e
}
.footerCopy {
	line-height: 1;
	font-size: 1.2rem;
	text-align: center
}
.footerCopy>.text {
	font-size: inherit
}
.mainVisual {
	position: relative
}
.mainVisual-body {
	position: absolute;
	left: 50%;
	top: 48px;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column nowrap;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%)
}
.mainVisual-body>.copy {
	width: 86px;
	margin-bottom: 16px
}
.mainVisual-body>.logo {
	width: 294px
}
.mainVisual-back {
	height: 600px
}
.sakuraBar {
	height: 80px;
	background: url(../img/cmn/sakuraBar_back.png) no-repeat 50% 50%;
	background-size: 36px 40px
}
.openingEvent {
	position: relative;
	padding: 32px 0 64px;
	overflow: hidden
}
.openingEvent-clip {
	position: absolute;
	top: 0;
	width: 50vw;
	height: 60px;
	background-color: #f3f1e6
}
.openingEvent-clip::after {
	content: '';
	position: absolute;
	top: 0;
	width: 0;
	height: 0;
	border-style: solid;
	border-color: transparent
}
.openingEvent-clip.-clip01 {
	right: calc(50% + 200px)
}
.openingEvent-clip.-clip01::after {
	left: 100%;
	border-top-color: #f3f1e6;
	border-width: 60px 100px 0 0
}
.openingEvent-clip.-clip02 {
	left: calc(50% + 200px)
}
.openingEvent-clip.-clip02::after {
	right: 100%;
	border-right-color: #f3f1e6;
	border-width: 0 100px 60px 0
}
.openingEvent-head {
	width: calc(100% - 40px);
	max-width: 336px;
	margin: 0 auto 36px
}
.openingEvent-date {
	position: relative;
	margin-bottom: 32px;
	text-align: center
}
.openingEvent-date>.title {
	position: absolute;
	left: calc(50% - 84px);
	top: -12px;
	width: 168px;
	line-height: 25px;
	background: url(../img/cmn/openingEvent_date_title.png) no-repeat 50% 50%/cover
}
.openingEvent-date>.text {
	padding: 20px 0 16px;
	line-height: 1;
	color: #fff;
	background-color: #da4c4a;
	font-size: 1.7rem
}
.openingEvent-text {
	margin-bottom: 32px
}
.openingEvent-movie>.title {
	width: 300px;
	margin: 0 auto 20px
}
.openingEvent-movie>.embed {
	width: 100%;
	height: calc((100vw - 40px)*9/16);
	margin: 0 auto
}
.castInfo {
	padding: 64px 0
}
.castInfo-head {
	position: relative;
	display: table;
	margin: 0 auto 24px;
	line-height: 1.2;
	text-align: center
}
.castInfo-head::after, .castInfo-head::before {
	content: '';
	position: absolute;
	background: no-repeat 50% 50%/cover
}
.castInfo-head::before {
	left: -60px;
	top: -32px;
	width: 40px;
	height: 24px;
	background-image: url(../img/cmn/castInfo_head_back01.png)
}
.castInfo-head::after {
	right: -80px;
	bottom: -4px;
	width: 52px;
	height: 12px;
	background-image: url(../img/cmn/castInfo_head_back02.png)
}
.castInfo-head>.lead {
	margin-bottom: 8px;
	font-size: 1.5rem
}
.castInfo-head>.title {
	font-size: 2.7rem
}
.castInfo-body {
	overflow: hidden
}
.castInfo-body>.space {
	float: right;
	height: 3.6em
}
.castInfo-body>.image {
	float: right;
	clear: both;
	width: 166px;
	margin: 12px 0 0 16px
}
.aboutArea {
	padding: 64px 0;
	background: #ebede8 url(../img/cmn/aboutArea_back_sp.jpg) no-repeat 50% 50%/cover
}
.photoSlide .swiper-slide {
	width: 237px
}
.photoSlide .swiper-slide>.link {
	display: block
}
.photoSlide .swiper-pagination {
	margin-top: 16px;
	line-height: 1;
	font-size: 1.4rem;
	text-align: right
}
.latestInfo-embed {
	max-width: 640px;
	margin: 0 auto;
	text-align: center
}
.timeline-TweetList-tweet {
	padding: 40px!important
}
.accessMap-embed {
	width: 100%;
	height: 272px;
	border: 2px solid #fff
}
.accessMap-addr, .accessMap-embed, .bannerList-item, .mapLink-item {
	margin-bottom: 32px
}
.accessMap-trans>.item {
	margin-bottom: 1px;
	padding: 40px;
	background-color: #fff
}
.accessMap-trans>.item>.title {
	position: relative;
	margin-bottom: .8em;
	padding-left: .8em;
	font-weight: 700
}
.accessMap-trans>.item>.title::before {
	content: '-';
	position: absolute;
	left: 0;
	top: 0
}
.mapLink-item>.link {
	display: block;
	max-width: 980px;
	margin: 0 auto;
	line-height: 1;
	border-radius: 4px;
	overflow: hidden
}
.bannerList-item>.title {
	margin-bottom: 12px;
	line-height: 1.5;
	font-size: 1.4rem;
	text-align: center
}
.bannerList-item>.link {
	display: block;
	width: 256px;
	margin: 0 auto;
	line-height: 1
}
.contactArea {
	padding: 48px 0;
	background-color: #fff
}
.contactArea>.l-wrap {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-flow: column nowrap;
	-webkit-box-align: center;
	align-items: center
}
.contactArea-text {
	line-height: 1.5;
	font-size: 1.4rem
}
.contactArea-link {
	line-height: 1.2;
	font-size: 2.2rem
}
.photoGallery-list {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-flow: row wrap;
	-webkit-box-pack: justify;
	justify-content: space-between
}
.photoGallery-list>.item {
	width: calc((100% - 16px)/2)
}
.photoGallery-list>.item:nth-child(n+3) {
	margin-top: 16px
}
#bp_container img {
	margin-bottom: auto!important
}
.areaMap-text {
	margin-bottom: 24px
}
.areaMap-image {
	border: 6px solid #c93735
}
.areaMap-image .zoomWrap {
	position: relative;
	cursor: default;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.areaMap-image .zoomContent {
	width: calc(100vw - 40px - 12px);
	height: calc((100vw - 40px - 12px)/646*932);
	-webkit-transform-origin: left top;
	transform-origin: left top;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}
.areaMap-image .image {
	width: 100%;
	height: 100%;
	background: no-repeat 50% 50%/cover
}
.areaMap-image .link {
	position: absolute;
	z-index: 2;
	width: 18%;
	height: 4.8%
}
.areaMap-image .link.link01 {
	left: 42%;
	top: 44.4%
}
.areaMap-image .link.link02 {
	left: 32.8%;
	top: 58.4%
}
.areaMap-image .link.link03 {
	width: 12%;
	left: 35.6%;
	top: 64.8%
}
.areaMap-image .link.link04 {
	width: 16%;
	height: 3.6%;
	left: 51.2%;
	top: 94.8%
}
.areaMap-image .link.link05 {
	left: 31.2%;
	top: 3.6%
}
pinch-zoom {
	position: relative;
	display: block
}
.areaIntro-head {
	margin-bottom: 8px
}
.areaIntro-body>.item {
	padding-top: 24px;
	overflow: hidden
}
.areaIntro-body>.item:not(:last-child) {
	padding-bottom: 24px;
	border-bottom: 2px dotted currentColor
}
.areaIntro-body>.item>.image {
	margin-bottom: 16px
}
.areaIntro-body>.item.-right>.image {
	float: right;
	margin-left: 16px
}
.areaIntro-body>.item>.title {
	margin-bottom: 16px;
	line-height: 1
}
.areaIntro-body>.item#place01>.image {
	width: 152px
}
.areaIntro-body>.item#place04>.image {
	width: 160px
}
.areaIntro-body>.item#place01>.title {
	width: calc(100% - 152px - 16px);
	max-width: 152px
}
.areaIntro-body>.item#place02>.title {
	max-width: 208px
}
.areaIntro-body>.item#place03>.title {
	max-width: 134px
}
.areaIntro-body>.item#place04>.title {
	max-width: 112px
}
.areaIntro-body>.item#place05>.title {
	max-width: 180px
}
.areaIntro-body>.item>.text>.link {
	color: #ff8d8b
}
.c-headline01 {
	position: relative;
	margin-bottom: 24px
}
.c-headline01>.title {
	position: relative;
	display: table;
	line-height: 1.2;
	font-size: 2.4rem
}
.c-headline01>.title>.small {
	font-size: 1.8rem
}
.c-headline01.-center>.title {
	margin: 0 auto;
	text-align: center
}
.c-headline01.-dots>.title::after, .c-headline01.-dots>.title::before {
	content: '';
	position: absolute;
	top: calc(50% - 21px);
	width: 25px;
	height: 41px;
	background: no-repeat 50% 50%/cover
}
.c-headline01.-dots>.title::before {
	left: -48px;
	background-image: url(../img/cmn/headline01_back_left.png)
}
.c-headline01.-dots>.title::after {
	right: -48px;
	background-image: url(../img/cmn/headline01_back_right.png)
}
.c-headline01>.link {
	position: relative;
	left: 42%;
	top: calc(50% - 10px);
	padding: 4px 16px 4px 4px;
	line-height: 1;
	font-size: 1.4rem;
	color: inherit;
	background: url(../img/cmn/icon_showall.svg) no-repeat 100% 50%;
	background-size: 10px 10px
}
:root {
	--swiper-theme-color: #007AFF
}
.swiper-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	z-index: 1
}
.swiper-slide, .swiper-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform
}
.swiper-wrapper {
	z-index: 1;
	display: -webkit-box;
	display: flex
}
.swiper-container-android .swiper-slide, .swiper-wrapper {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}
.swiper-container-free-mode>.swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto
}
.swiper-slide {
	flex-shrink: 0
}
.swiper-slide-invisible-blank {
	visibility: hidden
}
.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide {
	height: auto
}
.swiper-container-autoheight .swiper-wrapper {
	-webkit-box-align: start;
	align-items: flex-start;
	-webkit-transition-property: height, -webkit-transform;
	transition-property: transform, height;
	transition-property: transform, height, -webkit-transform
}
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000
}
@media screen {
	[hidden~=screen] {
		display: inherit
	}
	[hidden~=screen]:not(:active):not(:focus):not(:target) {
		position: absolute!important;
		clip: rect(0 0 0 0)!important
	}
}
@media print, screen and (min-width:750px) {
	body {
		font-size: 2.4rem
	}
	.disp-sp {
		display: none!important
	}
	body:not(.p-home) {
		padding-top: 128px
	}
	.l-wrap {
		width: 1020px
	}
	.l-wrap.-blue {
		padding-top: 64px;
		padding-bottom: 64px
	}
	.l-section {
		margin-bottom: 128px
	}
	.l-main.-ptb {
		padding: 96px 0
	}
	#header, #toggle {
		height: 128px
	}
	.headerLogo {
		width: 200px
	}
	#toggle {
		width: 128px;
		padding-top: 16px
	}
	.toggleBtn {
		width: 66px;
		height: 36px;
		margin-bottom: 16px
	}
	.toggleBtn>.bar {
		height: 4px
	}
	.h-opened .toggleBtn>.bar.bar01 {
		-webkit-transform: translateY(16px) rotate(45deg);
		transform: translateY(16px) rotate(45deg)
	}
	.h-opened .toggleBtn>.bar.bar03 {
		-webkit-transform: translateY(-16px) rotate(-45deg);
		transform: translateY(-16px) rotate(-45deg)
	}
	.toggleText {
		font-size: 2rem
	}
	.navLogo {
		width: 322px;
		margin-bottom: 32px
	}
	.navList {
		max-width: 540px
	}
	.navList>.item {
		height: 80px
	}
	#footer {
		padding: 64px 0
	}
	.footerCopy, .navList>.item {
		font-size: 2.4rem
	}
	.mainVisual-body {
		top: 96px
	}
	.mainVisual-body>.copy {
		width: 120px;
		margin-bottom: 32px
	}
	.mainVisual-body>.logo {
		width: 400px
	}
	.mainVisual-back {
		height: 800px
	}
	.sakuraBar {
		height: 180px;
		background-size: 72px 80px
	}
	.openingEvent {
		padding: 64px 0 128px
	}
	.openingEvent-clip {
		height: 120px
	}
	.openingEvent-clip.-clip01 {
		right: calc(50% + 500px)
	}
	.openingEvent-clip.-clip01::after {
		border-width: 120px 200px 0 0
	}
	.openingEvent-clip.-clip02 {
		left: calc(50% + 500px)
	}
	.openingEvent-clip.-clip02::after {
		border-width: 0 200px 120px 0
	}
	.openingEvent-head {
		max-width: 672px;
		margin-bottom: 72px
	}
	.openingEvent-date, .openingEvent-text {
		margin-bottom: 48px
	}
	.openingEvent-date>.title {
		left: calc(50% - 168px);
		top: -24px;
		width: 336px;
		line-height: 50px;
		font-size: 1.4rem
	}
	.openingEvent-date>.text {
		font-size: 2.8rem;
		padding: 40px 0 24px
	}
	.openingEvent-movie>.title {
		width: 484px;
		margin-bottom: 40px
	}
	.openingEvent-movie>.embed {
		width: 670px;
		height: 454px
	}
	.castInfo {
		padding: 128px 0
	}
	.castInfo-head {
		margin-bottom: 48px
	}
	.castInfo-head::before {
		left: -160px;
		top: -64px;
		width: 80px;
		height: 48px
	}
	.castInfo-head::after {
		right: -160px;
		bottom: -16px;
		width: 104px;
		height: 24px
	}
	.castInfo-head>.lead {
		font-size: 2.8rem;
		margin-bottom: 16px
	}
	.castInfo-head>.title {
		font-size: 4.2rem
	}
	.castInfo-body>.image {
		width: 272px;
		margin: 12px 0 0 72px
	}
	.aboutArea {
		padding: 160px 0;
		background-image: url(../img/cmn/aboutArea_back_pc.jpg)
	}
	.photoSlide .swiper-slide {
		width: 574px
	}
	.photoSlide .swiper-pagination {
		font-size: 2.8rem;
		margin-top: 32px
	}
	.accessMap-embed {
		height: 544px
	}
	.accessMap-addr, .accessMap-embed {
		margin-bottom: 48px
	}
	.accessMap-trans>.item {
		padding: 80px
	}
	.bannerList-item, .mapLink-item {
		margin-bottom: 64px
	}
	.mapLink-item>.link {
		border-radius: 8px
	}
	.bannerList-item>.title {
		font-size: 2.4rem;
		margin-bottom: 32px
	}
	.bannerList-item>.link {
		width: 432px
	}
	.contactArea {
		padding: 64px 0
	}
	.contactArea-text {
		font-size: 2.4rem;
		margin-bottom: 4px
	}
	.contactArea-link {
		font-size: 3.6rem
	}
	.photoGallery-list>.item {
		width: calc((100% - 120px)/4)
	}
	.photoGallery-list>.item:nth-child(3), .photoGallery-list>.item:nth-child(4) {
		margin-top: 0
	}
	.photoGallery-list>.item:nth-child(n+5) {
		margin-top: 40px
	}
	.photoGallery-list::after, .photoGallery-list::before {
		content: '';
		-webkit-box-ordinal-group: 3;
		order: 2;
		width: calc((100% - 120px)/4)
	}
	.areaMap-text {
		margin-bottom: 48px
	}
	.areaMap-image {
		border-width: 12px
	}
	.areaMap-image .zoomContent {
		width: 646px;
		height: 932px
	}
	.areaIntro-head {
		margin-bottom: 16px
	}
	.areaIntro-body>.item {
		padding-top: 48px
	}
	.areaIntro-body>.item:not(:last-child) {
		padding-bottom: 48px;
		border-bottom-width: 4px
	}
	.areaIntro-body>.item>.image, .areaIntro-body>.item>.title {
		margin-bottom: 24px
	}
	.areaIntro-body>.item.-right>.image {
		margin-left: 32px
	}
	.areaIntro-body>.item#place01>.image {
		width: 272px
	}
	.areaIntro-body>.item#place04>.image {
		width: 232px
	}
	.areaIntro-body>.item#place01>.title {
		width: calc(100% - 272px - 32px);
		max-width: 264px
	}
	.areaIntro-body>.item#place02>.title {
		max-width: 348px
	}
	.areaIntro-body>.item#place03>.title {
		max-width: 224px
	}
	.areaIntro-body>.item#place04>.title {
		max-width: 184px
	}
	.areaIntro-body>.item#place05>.title {
		max-width: 300px
	}
	.c-headline01 {
		margin-bottom: 48px
	}
	.c-headline01>.title {
		font-size: 4.2rem
	}
	.c-headline01>.title>.small {
		font-size: 3.6rem
	}
	.c-headline01.-dots>.title::after, .c-headline01.-dots>.title::before {
		width: 50px;
		height: 82px;
		top: calc(50% - 41px)
	}
	.c-headline01.-dots>.title::before {
		left: -96px
	}
	.c-headline01.-dots>.title::after {
		right: -96px
	}
	.c-headline01>.link {
		font-size: 2.4rem;
		top: calc(50% - 20px);
		padding: 8px 32px 8px 8px;
		background-size: 20px 20px
	}
}
@media screen and (max-width:749px) {
	.disp-pc {
		display: none!important
	}
	.l-page {
		overflow-x: hidden
	}
}
@media print, screen and (min-width:750px) and (min-width:750px) {
	.openingEvent-date>.title {
		font-size: 2.8rem
	}
}
.aboutArea .link-txt {
	text-align: center;
	margin-top: 40px;
}
.aboutArea .link-txt a {
	font-size: 24px;
	font-weight: 400;
}
@media screen and (max-width: 749px) {
	.aboutArea .link-txt a {
		font-size: 1.6rem;
	}
}
.maturi-banner {
	margin: 40px auto;
	max-width: 840px;
	padding: 0 20px;
}
.maturi-banner a {
	display: block;
	width: 100%;
	height: 100%;
}
.maturi-banner a img {
	max-width: 100%;
}